// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import HomePage from './components/HomePage';
import Entries from './components/Entries';
import Results from './components/Results';
import HorseStats from './components/HorseStats';
import PrizeMoney from './components/PrizeMoney';

function App() {
  return (
    <Router>
      <Navbar />
      <div style={{ padding: '1rem' }}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/entries" element={<Entries />} />
          <Route path="/results" element={<Results />} />
          <Route path="/horse-stats" element={<HorseStats />} />
          <Route path="/prize-money" element={<PrizeMoney />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
