import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { getComparator, stableSort } from './utils/SortArray';

function Entries() {
  const [entries, setEntries] = useState([]);
  const [filteredEntries, setFilteredEntries] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('RaceDate');
  const [error, setError] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [uniqueUsers, setUniqueUsers] = useState([]);
  const [allSelected, setAllSelected] = useState(false);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    axios
      .get(`${apiBaseUrl}/data/Entries.RDS?season=2024-2025`)
      .then((response) => {
        const data = response.data;
        setEntries(data);
        setFilteredEntries(data);
        
        // Extract unique user columns (those prefixed with 'userCols.')
        const users = Object.keys(data[0] || {}).filter((key) =>
          key.startsWith('userCols.')
        ).map((user) => user.replace('userCols.', ''));
        const userNames = users.map((user) => user.replace('userCols.', ''));
        setUniqueUsers(userNames);
      })
      .catch((error) => {
        console.error("Error fetching entries data:", error);
        setError("Failed to load entries data.");
      });
  }, [apiBaseUrl]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleUserCheckboxChange = (event) => {
    const user = event.target.name;
    const isChecked = event.target.checked;
    
    if (user === 'all') {
      setAllSelected(isChecked);
      setSelectedUsers(isChecked ? uniqueUsers : []);
      setFilteredEntries(isChecked ? entries : []);
    } else {
      const updatedUsers = isChecked
        ? [...selectedUsers, user]
        : selectedUsers.filter((u) => u !== user);

      setSelectedUsers(updatedUsers);
      setAllSelected(updatedUsers.length === uniqueUsers.length);

      if (updatedUsers.length > 0) {
        setFilteredEntries(
          entries.filter((entry) =>
            updatedUsers.some((user) => entry[`userCols.${user}`] > 0)
          )
        );
      } else {
        setFilteredEntries(entries);
      }
    }
  };

  const addCompetitorsColumn = (entry) => {
    const competitors = uniqueUsers
      .filter((user) => entry[`userCols.${user}`] > 0)
      .sort();
  
    if (competitors.length === 1) {
      return `${competitors[0]} 🐺`; 
    }
  
    return competitors.join(', ');
  };

  const columns = [
    { id: 'RaceDate', label: 'Race Date' },
    { id: 'HorseName', label: 'Horse Name' },
    { id: 'Course', label: 'Course' },
    { id: 'RaceTitle', label: 'Race Title' },
    { id: 'Competitors', label: 'Competitors' },
  ];

  return (
    <div style={{ padding: '1rem' }}>
      <h2>Entries</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}

      {/* Competitors filter section with h3 header */}
      <div style={{ marginBottom: '1rem' }}>
        <h3>Competitors</h3>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={allSelected}
                onChange={handleUserCheckboxChange}
                name="all"
              />
            }
            label="All Competitors"
          />
          {uniqueUsers.map((user) => (
            <FormControlLabel
              key={user}
              control={
                <Checkbox
                  checked={selectedUsers.includes(user)}
                  onChange={handleUserCheckboxChange}
                  name={user}
                />
              }
              label={user}
            />
          ))}
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table aria-label="entries table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  sortDirection={orderBy === column.id ? order : false}
                  style={{ fontWeight: 'bold' }} 
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : 'asc'}
                    onClick={(event) => handleRequestSort(event, column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(filteredEntries, getComparator(order, orderBy)).map((entry, index) => (
              <TableRow key={index}>
                <TableCell>{entry.RaceDate}</TableCell>
                <TableCell>{entry.HorseName}</TableCell>
                <TableCell>{entry.Course}</TableCell>          
                <TableCell>{entry.RaceTitle}</TableCell>       
                <TableCell>{addCompetitorsColumn(entry)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default Entries;
