function descendingComparator(a, b, orderBy) {
  const aValue = a[orderBy];
  const bValue = b[orderBy];
  const isAEmpty = aValue === '' || aValue === null || aValue === undefined;
  const isBEmpty = bValue === '' || bValue === null || bValue === undefined;

  if (isAEmpty && isBEmpty) {
    return 0;
  } else if (isAEmpty || aValue === null || aValue === undefined) {
    return 1;
  } else if (isBEmpty || bValue === null || bValue === undefined) {
    return -1;
  }

  if (bValue < aValue) {
    return -1;
  }
  if (bValue > aValue) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
