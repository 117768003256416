import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { getComparator, stableSort } from './utils/SortArray';

function Results() {
  const [results, setResults] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('RaceDate');
  const [error, setError] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [uniqueUsers, setUniqueUsers] = useState([]);
  const [allUsersSelected, setAllUsersSelected] = useState(false);
  const [selectedMonths, setSelectedMonths] = useState([]);
  const months = useMemo(() => ['Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Fez', 'Apr'], []);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    axios
      .get(`${apiBaseUrl}/data/Results.RDS?season=2024-2025`)
      .then((response) => {
        const data = response.data;
        setResults(data);
        setFilteredResults(data);

        // Extract unique user columns dynamically
        const users = Object.keys(data[0] || {})
          .filter((key) => key.startsWith('userCols.'))
          .map((user) => user.replace('userCols.', ''));
        setUniqueUsers(users);
      })
      .catch((error) => {
        console.error("Error fetching results data:", error);
        setError("Failed to load results data.");
      });
  }, [apiBaseUrl]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleUserCheckboxChange = (event) => {
    const user = event.target.name;
    const isChecked = event.target.checked;

    if (user === 'all') {
      setAllUsersSelected(isChecked);
      setSelectedUsers(isChecked ? uniqueUsers : []);
      applyFilters(isChecked ? uniqueUsers : [], selectedMonths);
    } else {
      const updatedUsers = isChecked
        ? [...selectedUsers, user]
        : selectedUsers.filter((u) => u !== user);

      setSelectedUsers(updatedUsers);
      setAllUsersSelected(updatedUsers.length === uniqueUsers.length);
      applyFilters(updatedUsers, selectedMonths);
    }
  };

  const handleMonthCheckboxChange = (event) => {
    const month = event.target.name;
    const isChecked = event.target.checked;

    const updatedMonths = isChecked
      ? [...selectedMonths, month]
      : selectedMonths.filter((m) => m !== month);

    setSelectedMonths(updatedMonths);
    applyFilters(selectedUsers, updatedMonths);
  };

  const applyFilters = (users, months) => {
    const filtered = results.filter((result) => {
      // Extract month from RaceDate
      const raceDate = new Date(result.RaceDate);
      const raceMonth = raceDate.toLocaleString('default', { month: 'short' });
  
      // Check if Fez was selected and if the result is part of the festival
      const isFezRace = result.Fez === 1;
  
      // If Fez is selected among months
      const fezSelected = months.includes('Fez');
  
      // Determine if the race's month matches the selected months or if it's a Fez race
      const monthMatch =
        months.length === 0 ||
        (months.includes(raceMonth) && !fezSelected) ||
        (fezSelected && isFezRace);
  
      const userMatch =
        users.length === 0 ||
        users.some((user) => result[`userCols.${user}`] > 0);
  
      return monthMatch && userMatch;
    });
  
    setFilteredResults(filtered);
  };

  const addCompetitorsColumn = (entry) => {
    const competitors = uniqueUsers
      .filter((user) => entry[`userCols.${user}`] > 0)
      .sort();
  
    if (competitors.length === 1) {
      return `${competitors[0]} 🐺`; 
    }
  
    return competitors.join(', ');
  };

  const columns = [
    { id: 'RaceDate', label: 'Race Date' },
    { id: 'HorseName', label: 'Horse Name' },
    { id: 'Course', label: 'Course' },
    { id: 'RaceTitle', label: 'Race Title' },
    { id: 'FinPos', label: 'Finishing Position' },
    { id: 'Points', label: 'Points' },
    { id: 'TotalRuns', label: 'Total Runs' },
    { id: 'TotalWins', label: 'Total Wins' },
    { id: 'BonusPoints', label: 'Bonus Points' },
    { id: 'Competitors', label: 'Competitors' },
  ];

  return (
    <div style={{ padding: '1rem' }}>
      <h2 style={{ fontWeight: 'bold' }}>Results</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}

      {/* Competitors Filter Section */}
      <div style={{ marginBottom: '1rem' }}>
        <h3>Competitors</h3>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={allUsersSelected}
                onChange={handleUserCheckboxChange}
                name="all"
              />
            }
            label="All Competitors"
          />
          {uniqueUsers.map((user) => (
            <FormControlLabel
              key={user}
              control={
                <Checkbox
                  checked={selectedUsers.includes(user)}
                  onChange={handleUserCheckboxChange}
                  name={user}
                />
              }
              label={user}
            />
          ))}
        </div>
      </div>

      {/* Months Filter Section */}
      <div style={{ marginBottom: '1rem' }}>
        <h3>Months</h3>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {months.map((month) => (
            <FormControlLabel
              key={month}
              control={
                <Checkbox
                  checked={selectedMonths.includes(month)}
                  onChange={handleMonthCheckboxChange}
                  name={month}
                />
              }
              label={month}
            />
          ))}
        </div>
      </div>

      {/* Results Table */}
      <TableContainer component={Paper}>
        <Table aria-label="results table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  sortDirection={orderBy === column.id ? order : false}
                  style={{ fontWeight: 'bold' }}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : 'asc'}
                    onClick={(event) => handleRequestSort(event, column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(filteredResults, getComparator(order, orderBy)).map((result, index) => (
              <TableRow key={index}>
                <TableCell>{result.RaceDate}</TableCell>
                <TableCell>{result.HorseName}</TableCell>
                <TableCell>{result.Course}</TableCell>
                <TableCell>{result.RaceTitle}</TableCell>
                <TableCell>{result.FinPos || 'DNF'}</TableCell>
                <TableCell>{result.Points}</TableCell>
                <TableCell>{result.TotalRuns}</TableCell>
                <TableCell>{result.TotalWins}</TableCell>
                <TableCell>{result.BonusPoints}</TableCell>
                <TableCell>{addCompetitorsColumn(result)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default Results;
