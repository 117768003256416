import React from 'react';
import { Link } from 'react-router-dom'; 

function Navbar() {
  return (
    <nav style={{ padding: '1rem', backgroundColor: '#333', color: '#fff' }}>
      <h1>AutoKev</h1>
      <div>
        <Link to="/" style={{ color: '#fff', marginRight: '1rem' }}>Leaderboard</Link>
        <Link to="/entries" style={{ color: '#fff', marginRight: '1rem' }}>Entries</Link>
        <Link to="/results" style={{ color: '#fff', marginRight: '1rem' }}>Results</Link>
        <Link to="/horse-stats" style={{ color: '#fff', marginRight: '1rem' }}>Horse Stats</Link>
        <Link to="/prize-money" style={{ color: '#fff', marginRight: '1rem' }}>Prize Money</Link>
      </div>
    </nav>
  );
}

export default Navbar;
