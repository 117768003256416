import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { getComparator, stableSort } from './utils/SortArray';

function HorseStats() {
  const [horseStats, setHorseStats] = useState([]);
  const [filteredHorseStats, setFilteredHorseStats] = useState([]);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('TotalPoints');
  const [error, setError] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [uniqueUsers, setUniqueUsers] = useState([]);
  const [allSelected, setAllSelected] = useState(false);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    axios
      .get(`${apiBaseUrl}/data/leagueMatrix.RDS?season=2024-2025`)
      .then((response) => {
        const data = response.data;
        setHorseStats(data);
        setFilteredHorseStats(data);

        // Extract unique user columns (those prefixed with 'userCols.')
        const users = Object.keys(data[0] || {}).filter((key) =>
          key.startsWith('userCols.')
        ).map((user) => user.replace('userCols.', ''));
        setUniqueUsers(users);
      })
      .catch((error) => {
        console.error("Error fetching horse stats data:", error);
        setError("Failed to load horse stats data.");
      });
  }, [apiBaseUrl]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleUserCheckboxChange = (event) => {
    const user = event.target.name;
    const isChecked = event.target.checked;

    if (user === 'all') {
      setAllSelected(isChecked);
      setSelectedUsers(isChecked ? uniqueUsers : []);
      setFilteredHorseStats(isChecked ? horseStats : []);
    } else {
      const updatedUsers = isChecked
        ? [...selectedUsers, user]
        : selectedUsers.filter((u) => u !== user);

      setSelectedUsers(updatedUsers);
      setAllSelected(updatedUsers.length === uniqueUsers.length);

      if (updatedUsers.length > 0) {
        setFilteredHorseStats(
          horseStats.filter((horse) =>
            updatedUsers.some((user) => horse[`userCols.${user}`] > 0)
          )
        );
      } else {
        setFilteredHorseStats(horseStats);
      }
    }
  };

  const addCompetitorsColumn = (horse) => {
    const competitors = uniqueUsers
      .filter((user) => horse[`userCols.${user}`] > 0)
      .sort()
      .join(', ');
    return competitors;
  };

  const columns = [
    { id: 'HorseNames', label: 'Horse Name' },  // Added Horse Name as the first column
    { id: 'Nov', label: 'Nov' },
    { id: 'Dec', label: 'Dec' },
    { id: 'Jan', label: 'Jan' },
    { id: 'Feb', label: 'Feb' },
    { id: 'Mar', label: 'Mar' },
    { id: 'Fez', label: 'Fez' },  // Cheltenham Festival points
    { id: 'Apr', label: 'Apr' },
    { id: 'TotalRuns', label: 'Total Runs' },
    { id: 'TotalWins', label: 'Total Wins' },
    { id: 'BonusPoints', label: 'Bonus Points' },
    { id: 'TotalPoints', label: 'Total Points' },
    { id: 'Competitors', label: 'Competitors' },
  ];

  return (
    <div style={{ padding: '1rem' }}>
      <h2>Horse Stats</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}

      {/* Competitors filter section with h3 header */}
      <div style={{ marginBottom: '1rem' }}>
        <h3>Competitors</h3>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={allSelected}
                onChange={handleUserCheckboxChange}
                name="all"
              />
            }
            label="All Competitors"
          />
          {uniqueUsers.map((user) => (
            <FormControlLabel
              key={user}
              control={
                <Checkbox
                  checked={selectedUsers.includes(user)}
                  onChange={handleUserCheckboxChange}
                  name={user}
                />
              }
              label={user}
            />
          ))}
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table aria-label="horse stats table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  sortDirection={orderBy === column.id ? order : false}
                  style={{ fontWeight: 'bold' }}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : 'asc'}
                    onClick={(event) => handleRequestSort(event, column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(filteredHorseStats, getComparator(order, orderBy)).map((horse, index) => (
              <TableRow key={index}>
                <TableCell>{horse.HorseNames}</TableCell>  {/* Horse Name column */}
                <TableCell>{horse.Nov}</TableCell>
                <TableCell>{horse.Dec}</TableCell>
                <TableCell>{horse.Jan}</TableCell>
                <TableCell>{horse.Feb}</TableCell>
                <TableCell>{horse.Mar}</TableCell>
                <TableCell>{horse.Fez}</TableCell>  {/* Cheltenham Festival points */}
                <TableCell>{horse.Apr}</TableCell>
                <TableCell>{horse.TotalRuns}</TableCell>
                <TableCell>{horse.TotalWins}</TableCell>
                <TableCell>{horse.TotalPoints - horse.TotalWins}</TableCell>  {/* Bonus Points */}
                <TableCell>{horse.TotalPoints}</TableCell>
                <TableCell>{addCompetitorsColumn(horse)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default HorseStats;
