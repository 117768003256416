import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import Avatar from '@mui/material/Avatar';
import { getComparator, stableSort } from './utils/SortArray';

function PrizeMoney() {
  const [prizeMoney, setPrizeMoney] = useState([]);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('TotalMoney');
  const [error, setError] = useState(null);

  const userAvatars = {
    "Charlie.H": { icon: '🚗', color: '#ff8a80' },
    "Charlie.McD": { icon: '🛥️', color: '#64b5f6' },
    "Jason": { icon: '🏠', color: '#ffb74d' },
    "John.W": { icon: '💼', color: '#81c784' },
    "Ken": { icon: '🧲', color: '#4db6ac' },
    "Kevan": { icon: '🐴', color: '#9575cd' },
    "Paddy": { icon: '🚂', color: '#e57373' },
    "Richard": { icon: '✈️', color: '#ba68c8' },
    "Sam": { icon: '📦', color: '#f06292' },
    "Tim": { icon: '🚀', color: '#64b5f6' },
  };

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    axios
      .get(`${apiBaseUrl}/data/PrizeMoney.RDS?season=2024-2025`)
      .then((response) => {
        console.log("API Response Data:", response.data);
        if (Array.isArray(response.data)) {
          setPrizeMoney(response.data);
        } else {
          console.error("Expected an array but got:", response.data);
          setPrizeMoney([]); 
        }
      })
      .catch((error) => {
        console.error("Error fetching prize money data:", error);
        setError("Failed to load prize money data.");
      });
  }, [apiBaseUrl]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const columns = [
    { id: 'User', label: 'Competitor' },
    { id: 'NovMoney', label: 'Nov' },
    { id: 'DecMoney', label: 'Dec' },
    { id: 'JanMoney', label: 'Jan' },
    { id: 'FebMoney', label: 'Feb' },
    { id: 'MarMoney', label: 'Mar' },
    { id: 'FezMoney', label: 'Fez' },
    { id: 'AprMoney', label: 'Apr' },
    { id: 'OverallMoney', label: 'Overall' },
    { id: 'TotalMoney', label: 'Total' },
  ];

  return (
    <div style={{ padding: '1rem' }}>
      <h2>Prize Money</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <TableContainer component={Paper}>
        <Table aria-label="prize money table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  sortDirection={orderBy === column.id ? order : false}
                  style={{ fontWeight: 'bold' }}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : 'asc'}
                    onClick={(event) => handleRequestSort(event, column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(prizeMoney, getComparator(order, orderBy)).map((user, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Avatar
                    style={{
                      backgroundColor: userAvatars[user.User]?.color || '#90a4ae',
                      marginRight: '8px',
                    }}
                  >
                    {userAvatars[user.User]?.icon || user.User.charAt(0)}
                  </Avatar>
                  {user.User}
                </TableCell>
                <TableCell>{user.NovMoney}</TableCell>
                <TableCell>{user.DecMoney}</TableCell>
                <TableCell>{user.JanMoney}</TableCell>
                <TableCell>{user.FebMoney}</TableCell>
                <TableCell>{user.MarMoney}</TableCell>
                <TableCell>{user.FezMoney}</TableCell>
                <TableCell>{user.AprMoney}</TableCell>
                <TableCell>{user.OverallMoney}</TableCell>
                <TableCell>{user.TotalMoney}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default PrizeMoney;
